<template>
  <div class="pos">
    <div
      class="pos-factura text-center mt-2"
      v-if="factura.factura != undefined"
    >
      <div class="header">
        <h2>
          {{ factura.header.razon }}
        </h2>
        <p class="folio">
          Factura de venta: {{ factura.header.prefijo }} -
          {{ factura.factura.folio | folio }}
          <br />
        </p>
        <!-- Header simplificado -->
        <p class="detalle" v-if="factura.header.regimen == 'simplificado'">
          Nit: {{ factura.header.nit }}
          <br />
          Teléfono: {{ factura.header.telefono }}
          <br />
          <br />
          Dirección: {{ factura.header.direccion }}
          <br />
          No responsable de IVA
          <br />
          Regimen Simple
          <br />
          Fecha:
          {{ moment(factura.factura.fecha).format("ll") }}
          {{
            moment(factura.factura.created_at, "YYYY-MM-DD HH:mm:ss").format(
              "h:mm A"
            )
          }}
        </p>
        <!-- Header Comun y comun con doc equivalente-->
        <p class="detalle" v-if="factura.header.regimen != 'simplificado'">
          Resolución: {{ factura.header.resolucion }} de
          {{ moment(factura.header.resolucion_fecha).format("ll") }}
          <br />
          Numeración de factura <br />
          de {{ factura.header.rango_desde }} a la
          {{ factura.header.rango_hasta }} prefijo {{ factura.header.prefijo }}
          <br />
          Vigencia: {{ factura.header.vigencia }}
          <br />
          Nit: {{ factura.header.nit }}
          <br />
          Teléfono: {{ factura.header.telefono }}
          <br />
          Dirección: {{ factura.header.direccion }}
          <br />
          Responsable de IVA
          <br />
          Regimen comun
          <br />
          Fecha:
          {{ moment(factura.factura.fecha).format("ll") }}
          {{
            moment(factura.factura.created_at, "YYYY-MM-DD HH:mm:ss").format(
              "h:mm A"
            )
          }}
        </p>
      </div>
      <div class="cliente">
        <table>
          <tbody>
            <tr>
              <th>Cliente</th>
              <td>
                {{ factura.factura.cliente }}
              </td>
            </tr>
            <tr>
              <th>Nit</th>
              <td>{{ factura.factura.cc_cliente }}</td>
            </tr>
            <tr>
              <th>Telefono</th>
              <td>{{ factura.factura.telefono }}</td>
            </tr>
            <tr>
              <th>Dirección</th>
              <td>{{ factura.factura.direccion }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="salidas">
        <!-- Regimen simplificado -->
        <table v-if="factura.header.regimen == 'simplificado'">
          <thead v-if="venta.length > 0">
            <tr>
              <th class="text-left">Producto</th>
              <th>Cant</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody v-if="venta.length > 0">
            <tr v-for="(item, index) in venta" :key="index">
              <td class="text-left" style="overflow-wrap: anywhere;">
                {{ item.salida }}
              </td>
              <td style="overflow-wrap: anywhere;">
                {{ item.cantidad }}
              </td>
              <td style="overflow-wrap: anywhere;">
                {{ item.total | currency }}
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th colspan="3" class="py-2"></th>
            </tr>
          </thead>
          <thead v-if="servicios.length > 0">
            <tr>
              <th class="text-left">Servicio</th>
              <th>Prof</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody v-if="servicios.length > 0">
            <tr v-for="(item, index) in servicios" :key="index">
              <td class="text-left" style="overflow-wrap: anywhere;">
                {{ item.salida }}
              </td>
              <td style="overflow-wrap: anywhere;">
                {{ item.profesional }}
              </td>
              <td style="overflow-wrap: anywhere;">
                {{ item.total | currency }}
              </td>
            </tr>
            <tr>
              <td class="py-2"></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2" class="text-left">Propina</th>
              <td>
                {{ factura.factura.propina | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left py-4">Total</th>
              <td>
                {{ parseFloat(factura.factura.total) | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Efectivo</th>
              <td>
                {{ factura.factura.efectivo | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Transferencia</th>
              <td>
                {{ factura.factura.transfer | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Datafono</th>
              <td>
                {{ factura.factura.datafono | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Puntos</th>
              <td>
                {{ factura.factura.puntos }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Bono</th>
              <td>
                {{ factura.factura.bono_valor | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Cortesia</th>
              <td>
                {{ factura.factura.cortesia | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Anticipos</th>
              <td>
                {{ factura.factura.total_anticipo | currency }}
              </td>
            </tr>
          </tfoot>
        </table>
        <!-- Regimen comun -->
        <table v-if="factura.header.regimen == 'comun'">
          <thead v-if="venta.length > 0">
            <tr>
              <th class="text-left">Producto</th>
              <th>Cant</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody v-if="venta.length > 0">
            <tr v-for="(item, index) in venta" :key="index">
              <td class="text-left">
                {{ item.salida }}
              </td>
              <td>
                {{ item.cantidad }}
              </td>
              <td>
                {{ item.subtotal | currency }}
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th colspan="3" class="py-2"></th>
            </tr>
          </thead>
          <thead v-if="servicios.length > 0">
            <tr>
              <th class="text-left">Servicio</th>
              <th>Prof</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody v-if="servicios.length > 0">
            <tr v-for="(item, index) in servicios" :key="index">
              <td class="text-left">
                {{ item.salida }}
              </td>
              <td>
                {{ item.profesional }}
              </td>
              <td>
                {{ item.subtotal | currency }}
              </td>
            </tr>
            <tr>
              <td class="py-2"></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2" class="text-left">Propina</th>
              <td>
                {{ factura.factura.propina | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left pt-4">Subtotal</th>
              <td class="pt-4">
                {{ parseFloat(factura.factura.subtotal) | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">IVA</th>
              <td>
                {{ parseFloat(factura.factura.iva) | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left pb-4">Total</th>
              <td class="pb-4">
                {{ parseFloat(factura.factura.total) | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Efectivo</th>
              <td>
                {{ factura.factura.efectivo | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Transferencia</th>
              <td>
                {{ factura.factura.transfer | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Datafono</th>
              <td>
                {{ factura.factura.datafono | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Puntos</th>
              <td>
                {{ factura.factura.puntos }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Bono</th>
              <td>
                {{ factura.factura.bono_valor | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Cortesia</th>
              <td>
                {{ factura.factura.cortesia | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Anticipos</th>
              <td>
                {{ factura.factura.total_anticipo | currency }}
              </td>
            </tr>
          </tfoot>
        </table>
        <!-- Regimen comun con documento equivalente -->
        <table v-if="factura.header.regimen == 'documento'">
          <thead v-if="productos.length > 0">
            <tr>
              <th class="text-left">Producto</th>
              <th>Cant</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in productos" :key="index">
              <td class="text-left">
                {{ item.salida }}
              </td>
              <td>{{ item.cantidad }} gr/uni</td>
              <td>
                {{ item.subtotal | currency }}
              </td>
            </tr>
            <tr>
              <td colspan="3" class="pt-2"></td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Subtotal</th>
              <td>
                {{ subtotal_productos | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">IVA</th>
              <td>
                {{ iva_productos | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Total</th>
              <td>
                {{ total_productos | currency }}
              </td>
            </tr>
            <tr>
              <td colspan="3" class="pt-2">
                <strong>
                  <small>Factura impresa por Grupo Caoba S.A.S</small>
                </strong>
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, index) in equivalente" :key="index">
            <tr>
              <td colspan="3" class="pt-2">
                <strong>
                  {{ item.profesional }}
                </strong>
                <br />
                Documento equivalente: {{ item.equivalente | folio }}
                <br />
                No responsable de IVA
                <br />
                Fecha:
                {{ moment(factura.factura.fecha).format("ll") }}
                {{ moment(factura.factura.created_at).format("h:mm A") }}
                <br />
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Servicio</th>
              <th>Valor</th>
            </tr>
            <tr>
              <td colspan="2" class="text-left">{{ item.salida }}</td>
              <td>
                {{ item.total | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Ingreso de terceros</th>
              <td>-{{ item.terceros | currency }}</td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Total</th>
              <td>{{ (item.total - item.terceros) | currency }}</td>
            </tr>
            <tr>
              <td colspan="3" class="py-2"></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th colspan="2" class="text-left">Propina</th>
              <td>
                {{ factura.factura.propina | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left py-4">Total</th>
              <td>
                {{ parseFloat(factura.factura.total) | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Efectivo</th>
              <td>
                {{ factura.factura.efectivo | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Transferencia</th>
              <td>
                {{ factura.factura.transfer | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Datafono</th>
              <td>
                {{ factura.factura.datafono | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Puntos</th>
              <td>
                {{ factura.factura.puntos }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Bono</th>
              <td>
                {{ factura.factura.bono_valor | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Cortesia</th>
              <td>
                {{ factura.factura.cortesia | currency }}
              </td>
            </tr>
            <tr>
              <th colspan="2" class="text-left">Anticipos</th>
              <td>
                {{ factura.factura.total_anticipo | currency }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <strong>
        <small>www.luxcultury.com</small>
      </strong>
    </div>
    <v-row>
      <v-col>
        <v-btn dark color="black" class="ml-2 d-print-none" @click="print()"
          >Imprimir</v-btn
        >
        <v-btn color="primary" class="ml-2 d-print-none" @click="close()"
          >Cerrar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "pos",
  data: () => ({
    factura: {},
  }),
  methods: {
    loadDetalleFactura() {
      const body = {
        route: "/detalle_factura",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.factura = response.data.data;
        }
      });
    },
    close() {
      window.close();
    },
    print() {
      window.print();
    },
    moment(...n) {
      if (n.length == 1) return moment(n[0]);
      else return moment(n[0], n[1]);
    },
  },
  created() {
    this.loadDetalleFactura();
  },
  mounted() {
    const body = {
      permisos: JSON.stringify(["informes", "recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    profesionales() {
      const profesionales = this.factura.distribucion.map((distri) => {
        let data = distri;
        if (distri.order_list) {
          data["order"] = true;
        } else {
          data["order"] = false;
        }

        if (data.order) {
          data["indexOrder"] = {
            primary: distri.order_list.split(".")[0],
            secondary: distri.order_list.split(".")[1],
          };
        }

        delete data.order_list;
        return data;
      });
      return profesionales;
    },
    servicios() {
      const servicios = this.factura.salida.filter((salida) => {
        return salida.categoria === "servicio";
      });

      let profesionales_lista = [];

      this.profesionales.forEach((pr) => {
        if (pr.order) {
          if (pr.indexOrder.secondary == "1") {
            profesionales_lista.push(pr);
          }
        } else {
          profesionales_lista.push(pr);
        }
      });

      return servicios.map((salida, index) => {
        salida.subtotal = parseFloat(salida.subtotal);
        salida.iva = parseFloat(salida.iva);
        salida.total = parseFloat(salida.total);
        const profesional = profesionales_lista.filter((prof) => {
          if (prof.order) return prof.indexOrder.primary == salida.order_list;
          else return prof.servicio_id == salida.servicio_id;
        })[0];
        return Object.assign(
          {
            profesional: profesional.profesional,
            equivalente: profesional.folio,
            nota_credito: false,
          },
          salida
        );
      });
    },
    venta() {
      return this.factura.salida.filter((salida) => {
        return salida.categoria === "venta";
      });
    },
    consumo() {
      return this.factura.salida.filter((salida) => {
        return salida.categoria === "consumo";
      });
    },
    productos() {
      return this.consumo.concat(this.venta);
    },
    equivalente() {
      return this.servicios.map((servicio) => {
        const consumos = this.consumo.filter((consumo) => {
          return consumo.servicio_id == servicio.servicio_id;
        });

        return Object.assign(
          {
            terceros: consumos.reduce((index, item) => {
              return index + parseFloat(item.total);
            }, 0),
          },
          servicio
        );
      });
    },
    subtotal_productos() {
      return this.productos.reduce((index, item) => {
        return index + parseFloat(item.subtotal);
      }, 0);
    },
    iva_productos() {
      return this.productos.reduce((index, item) => {
        return index + parseFloat(item.iva);
      }, 0);
    },
    total_productos() {
      return this.productos.reduce((index, item) => {
        return index + parseFloat(item.total);
      }, 0);
    },
  },
};
</script>

<style scoped>
.pos-factura {
  font-family: Arial;
  max-width: 80mm;
  margin: 0 auto;
  background: white;
  line-height: normal !important;
  color: black !important;
}
.header {
  font-weight: bold;
}
.header p {
  font-size: 14px;
}

.detalle {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table {
  width: 100%;
  padding: 8px;
}
table thead th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.cliente td,
th {
  font-size: 14px !important;
}

.cliente th {
  text-align: left !important;
}
.cliente td {
  text-align: right !important;
}

.salidas td,
th {
  font-size: 14px !important;
}
</style>
